<template>
  <div class="cuadre-caja mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Cuadre de caja </v-card-title>
          <v-card-text>
            <v-row dense>
              <!-- Fecha -->
              <v-col cols="12" md="4" sm="6">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu = false"
                    @change="
                      loadCuadreCaja();
                      loadCierresZ();
                    "
                  ></v-date-picker>
                </v-menu>
                <strong
                  >Ultimo cierre generado
                  <span v-if="cierres.length != 0">{{
                    moment(cierres[0].fecha, "YYYY-MM-DD").format("ll")
                  }}</span>
                </strong>
              </v-col>
              <!-- Descargar cuadre -->
              <v-col cols="12" md="3" sm="4">
                <v-btn color="black" dark @click="download()">
                  <v-icon left>mdi-download</v-icon>
                  Descargar
                </v-btn>
              </v-col>
              <!-- Generar z -->
              <v-col cols="12" md="2" sm="4" v-if="!dia_cierre">
                <v-btn
                  color="success"
                  :loading="loading"
                  :disabled="loading"
                  @click="generarz()"
                >
                  Generar Z
                </v-btn>
              </v-col>
              <!-- Descargar detalle -->
              <v-col cols="12" md="2" sm="4">
                <detalle-caja
                  :date="{ inicio: date, fin: date }"
                  :list="info_profesionales"
                />
              </v-col>
              <!-- Cuadre de caja -->
              <v-col cols="12" md="12" sm="12">
                <v-simple-table
                  dense
                  v-if="cuadre.pagos != undefined"
                  class="mt-4"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th>Profesional</th>
                        <th>Venta</th>
                        <th>Total facturado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(item, index) in info_profesionales"
                        :key="index"
                      >
                        <td>
                          <span v-if="item.id != null && item.ventas > 0">
                            <router-link
                              :to="`/detalleCaja/${item.id}/${date}/${date}`"
                            >
                              {{ item.profesional }}
                            </router-link>
                          </span>
                          <span v-else>
                            {{ item.profesional }}
                          </span>
                        </td>
                        <td>{{ item.ventas }}</td>
                        <td>{{ item.total | currency }}</td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th>Totalización</th>
                        <th>{{ total_ventas_profesionales }}</th>
                        <th>Total facturado</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th colspan="2">Total profesionales</th>
                        <td>{{ total_profesionales | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Total caja</th>
                        <td>{{ cuadre.caja | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Total productos consumo</th>
                        <td>{{ cuadre.consumos | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">
                          Total productos venta <small>(contado)</small>
                        </th>
                        <td>{{ cuadre.ventas | currency }}</td>
                      </tr>
                      <!-- <tr>
                        <th colspan="2">Total bonos regalo</th>
                        <td>{{ total_bonos | currency }}</td>
                      </tr> -->
                      <tr>
                        <th colspan="2">Propina</th>
                        <td>{{ cuadre.propina | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Subtotal</th>
                        <td>
                          {{
                            (cuadre.valores.subtotal + cuadre.propina)
                              | currency
                          }}
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">IVA</th>
                        <td>{{ cuadre.valores.iva | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2" class="active">Total</th>
                        <td class="active">
                          {{
                            (cuadre.valores.total + cuadre.propina) | currency
                          }}
                        </td>
                      </tr>
                    </tbody>
                    <thead>
                      <tr>
                        <th colspan="2">Métodos de pago</th>
                        <th>Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <tr v-on="on" v-bind="attrs">
                            <th colspan="2">Efectivo (+)</th>
                            <td style="color: green">
                              {{ caja_efectivo | currency }}
                            </td>
                          </tr>
                        </template>
                        <span
                          >Método de pago tenido en cuenta para la
                          totalización</span
                        >
                      </v-tooltip>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <tr v-on="on" v-bind="attrs">
                            <th colspan="2">Transferencia (+)</th>
                            <td style="color: green">
                              {{ caja_transferencia | currency }}
                            </td>
                          </tr>
                        </template>
                        <span
                          >Método de pago tenido en cuenta para la
                          totalización</span
                        >
                      </v-tooltip>
                      <v-tooltip top max-width="300">
                        <template v-slot:activator="{ on, attrs }">
                          <tr v-on="on" v-bind="attrs">
                            <th colspan="2">Datáfono (+)</th>
                            <td style="color: green">
                              {{ caja_datafono | currency }}
                            </td>
                          </tr>
                        </template>
                        <span
                          >Método de pago tenido en cuenta para la
                          totalización</span
                        >
                      </v-tooltip>
                      <tr>
                        <th colspan="2">Bono regalo</th>
                        <td>{{ cuadre.metodos.bonos | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Puntos</th>
                        <td>{{ cuadre.metodos.puntos }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Cortesia</th>
                        <td>{{ cuadre.metodos.cortesia | currency }}</td>
                      </tr>
                      <tr>
                        <th colspan="2">Anticipos</th>
                        <td>{{ cuadre.metodos.anticipos | currency }}</td>
                      </tr>
                      <tr>
                        <th class="active" colspan="2">
                          Total metodos de pago
                        </th>
                        <th class="active">
                          {{ total_metodos | currency }}
                        </th>
                      </tr>
                      <tr>
                        <th colspan="2">
                          Abonos a prestamos profesionales (+)
                        </th>
                        <td style="color: green">
                          {{ total_abonos_prof | currency }}
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">
                          Total abonos facturas por cobrar (+)
                        </th>
                        <td style="color: green">
                          {{ total_abonos_credito | currency }}
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Abono a proveedores (-)</th>
                        <td style="color: red">
                          {{ total_abonos_prov | currency }}
                        </td>
                      </tr>
                      <tr>
                        <th colspan="2">Total gastos (-)</th>
                        <td style="color: red">
                          {{ total_gastos | currency }}
                        </td>
                      </tr>
                      <tr>
                        <th class="active" colspan="2">Total</th>
                        <th class="active">
                          {{ total_metodos_movimientos | currency }}
                        </th>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
              <!-- Lista de gastos -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined v-if="cuadre.pagos != undefined">
                  <v-card-title>
                    Gastos {{ total_gastos | currency }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="viewDetailDialog('gastos')"
                        >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO (-)</th>
                            <td style="color: red">
                              {{ cuadre.gastos.efectivo | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA (-)</th>
                            <td style="color: red">
                              {{ cuadre.gastos.transferencia | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO (-)</th>
                            <td style="color: red">
                              {{ cuadre.gastos.datafono | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Bonos regalos -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined v-if="cuadre.bonos != undefined">
                  <v-card-title>
                    Total venta de bonos regalos
                    {{ total_bonos | currency }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="viewDetailDialog('bonos')"
                        >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO (+)</th>
                            <td style="color: green">
                              {{ cuadre.bonos.efectivo | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA (+)</th>
                            <td style="color: green">
                              {{ cuadre.bonos.transferencia | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO (+)</th>
                            <td style="color: green">
                              {{ cuadre.bonos.datafono | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Anticipos -->
              <v-col cols="12" md="6" sm="6">
                <v-card
                  elevation="0"
                  outlined
                  v-if="cuadre.anticipos != undefined"
                >
                  <v-card-title>
                    Total anticipos
                    {{ total_anticipos | currency }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="viewDetailDialog('anticipos')"
                        >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO (+)</th>
                            <td style="color: green">
                              {{ cuadre.anticipos.efectivo | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA (+)</th>
                            <td style="color: green">
                              {{ cuadre.anticipos.transferencia | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO (+)</th>
                            <td style="color: green">
                              {{ cuadre.anticipos.datafono | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Abono a prestamos -->
              <v-col cols="12" md="6" sm="6">
                <v-card
                  elevation="0"
                  outlined
                  v-if="cuadre.anticipos != undefined"
                >
                  <v-card-title>
                    Total abonos a prestamos Prof
                    {{ total_abonos_prof | currency }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="viewDetailDialog('abonosP')"
                        >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO (+)</th>
                            <td style="color: green">
                              {{ cuadre.abonos.prestamos.efectivo | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA (+)</th>
                            <td style="color: green">
                              {{
                                cuadre.abonos.prestamos.transferencia | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO (+)</th>
                            <td style="color: green">
                              {{ cuadre.abonos.prestamos.datafono | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Abonos a proveedores -->
              <v-col cols="12" md="6" sm="6">
                <v-card
                  elevation="0"
                  outlined
                  v-if="cuadre.anticipos != undefined"
                >
                  <v-card-title>
                    Total abonos a proveedores
                    {{ total_abonos_prov | currency }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="viewDetailDialog('abonosPro')"
                        >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO (+)</th>
                            <td style="color: red">
                              {{
                                cuadre.abonos.proveedores.efectivo | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA (+)</th>
                            <td style="color: red">
                              {{
                                cuadre.abonos.proveedores.transferencia
                                  | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO (+)</th>
                            <td style="color: red">
                              {{
                                cuadre.abonos.proveedores.datafono | currency
                              }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Abonos a credito -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined v-if="cuadre.pagos != undefined">
                  <v-card-title>
                    Abono a Facturas por Cobrar
                    {{ total_abonos_credito | currency }}
                    <v-spacer></v-spacer>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          color="primary"
                          icon
                          v-on="on"
                          v-bind="attrs"
                          @click="viewDetailDialog('abonosFa')"
                        >
                          <v-icon>mdi-table-of-contents</v-icon>
                        </v-btn>
                      </template>
                      <span>Ver detalle</span>
                    </v-tooltip>
                  </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO (+)</th>
                            <td style="color: green">
                              {{ cuadre.abonos.facturas.efectivo | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA (+)</th>
                            <td style="color: green">
                              {{
                                cuadre.abonos.facturas.transferencia | currency
                              }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO (+)</th>
                            <td style="color: green">
                              {{ cuadre.abonos.facturas.datafono | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Total saldos metodos de pago -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined v-if="cuadre.pagos != undefined">
                  <v-card-title> Saldos netos </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>EFECTIVO</th>
                            <td>
                              {{ caja_efectivo_movimientos | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>TRANSFERENCIA</th>
                            <td>
                              {{ caja_transferencia_movimientos | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>DATAFONO</th>
                            <td>
                              {{ caja_datafono_movimientos | currency }}
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Cierres del mes -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-title> Cierres del mes </v-card-title>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>Creado</th>
                            <th>Fecha</th>
                            <th>Hora</th>
                            <th>Imprimir</th>
                            <th>Eliminar</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr v-for="(item, index) in cierres" :key="index">
                            <td>
                              {{
                                moment(
                                  item.created_at,
                                  "YYYY-MM-DD HH:mm:ss"
                                ).format("ll")
                              }}
                            </td>
                            <td>
                              {{
                                moment(item.fecha, "YYYY-MM-DD").format("ll")
                              }}
                            </td>
                            <td>
                              {{
                                moment(item.hora, "HH:mm:ss").format("h:mm A")
                              }}
                            </td>
                            <td>
                              <router-link
                                :to="`/print_cierre/${item.id}`"
                                target="_blank"
                              >
                                <v-btn fab small color="primary" class="my-2">
                                  <v-icon>mdi-printer</v-icon>
                                </v-btn>
                              </router-link>
                            </td>
                            <td>
                              <v-btn
                                fab
                                small
                                color="error"
                                class="my-2"
                                @click="deleteCierre(item.id)"
                              >
                                <v-icon>mdi-delete</v-icon>
                              </v-btn>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <detalle-dialog
              :content="contentDialogDetail"
              v-on:changePage="changePage($event)"
              ref="detalleDialog"
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
import detalleCaja from "../../components/DetalleCaja.vue";
import detalleDialog from "../../components/cuadre/detalleDialog.vue";
export default {
  components: { detalleCaja, detalleDialog },
  name: "cuadreCaja",
  data: () => ({
    menu: false,
    date: moment().format("YYYY-MM-DD"),
    profesionales: [],
    cuadre: {},
    loading: false,
    cierres: [],
    headers: [
      {
        text: "Creado",
        value: "creado",
        align: "start",
        sortable: false,
      },
      {
        text: "Fecha",
        value: "fecha",
        sortable: false,
      },
      {
        text: "Hora",
        value: "hora",
        sortable: false,
      },
      {
        text: "Eliminar",
        value: "eliminar",
        sortable: false,
      },
    ],
    contentDialogDetail: null,
  }),
  methods: {
    loadCuadreCaja() {
      const body = {
        route: "/cuadre_caja",
        params: {
          date: {
            inicio: this.date,
            fin: this.date,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.cuadre = response.data.data;
      });
    },
    loadProfesionales() {
      const body = {
        route: "/profesionales_sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data.filter((p) => {
            return p.profesional;
          });
        }
      });
    },
    loadCierresZ() {
      const body = {
        route: "/cierres_z",
        params: {
          date: {
            inicio: moment(this.date).startOf("month").format("YYYY-MM-DD"),
            fin: this.date,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.cierres = response.data.data;
        }
      });
    },
    generarz() {
      this.loading = true;
      const body = {
        route: "/regitrar_cierre_z",
        data: {
          cierre: {
            fecha: this.date,
            hora: moment().format("HH:mm:ss"),
          },
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadCierresZ();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        })
        .finally(() => (this.loading = false));
    },
    deleteCierre(id) {
      const body = {
        route: "/delete_cierre_z",
        data: {
          id,
        },
      };
      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.loadCierresZ();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    download() {
      const total_ventas = this.total_ventas_profesionales;
      const name = `caja-${this.date}`;
      let data = this.info_profesionales.map((p) => {
        return {
          Columna_A: p.profesional,
          Columna_B: p.ventas,
          Columna_C: p.total,
        };
      });
      data.unshift(
        {
          Columna_A: "Fecha inicial",
          Columna_B: "",
          Columna_C: moment(this.date).format("ll"),
        },
        {
          Columna_A: "Fecha final",
          Columna_B: "",
          Columna_C: moment(this.date).format("ll"),
        }
      );
      const body = [
        {
          Columna_A: "Totalización",
          Columna_B: total_ventas,
          Columna_C: "Total facturado",
        },
        {
          Columna_A: "Total profesionales",
          Columna_B: "",
          Columna_C: this.total_profesionales,
        },
        {
          Columna_A: "Total caja",
          Columna_B: "",
          Columna_C: this.cuadre.caja,
        },
        {
          Columna_A: "Total productos consumo",
          Columna_B: "",
          Columna_C: this.cuadre.consumos,
        },
        {
          Columna_A: "Total productos venta (contado)",
          Columna_B: "",
          Columna_C: this.cuadre.ventas,
        },
        // {
        //   Columna_A: "Total bonos regalo",
        //   Columna_B: "",
        //   Columna_C: this.total_bonos,
        // },
        {
          Columna_A: "Propina",
          Columna_B: "",
          Columna_C: this.cuadre.propina,
        },
        {
          Columna_A: "Subtotal",
          Columna_B: "",
          Columna_C: this.cuadre.valores.subtotal + this.cuadre.propina,
        },
        {
          Columna_A: "IVA",
          Columna_B: "",
          Columna_C: this.cuadre.valores.iva,
        },
        {
          Columna_A: "Total",
          Columna_B: "",
          Columna_C: this.cuadre.valores.total + this.cuadre.propina,
        },
        {
          Columna_A: "Métodos de pago",
          Columna_B: "",
          Columna_C: "Total",
        },
        {
          Columna_A: "Efectivo",
          Columna_B: "",
          Columna_C: this.caja_efectivo,
        },
        {
          Columna_A: "Transferencia",
          Columna_B: "",
          Columna_C: this.caja_transferencia,
        },
        {
          Columna_A: "Datáfono",
          Columna_B: "",
          Columna_C: this.caja_datafono,
        },
        {
          Columna_A: "Bono regalo",
          Columna_B: "",
          Columna_C: this.cuadre.metodos.bonos,
        },
        {
          Columna_A: "Puntos",
          Columna_B: "",
          Columna_C: this.cuadre.metodos.puntos,
        },
        {
          Columna_A: "Cortesia",
          Columna_B: "",
          Columna_C: this.cuadre.metodos.cortesia,
        },
        {
          Columna_A: "Anticipos",
          Columna_B: "",
          Columna_C: this.cuadre.metodos.anticipos,
        },
        {
          Columna_A: "Total metodos de pago",
          Columna_B: "",
          Columna_C: this.total_metodos,
        },
        {
          Columna_A: "Abonos a prestamos profesionales (+)",
          Columna_B: "",
          Columna_C: this.total_abonos_prof,
        },
        {
          Columna_A: "Total abonos facturas por cobrar (+)",
          Columna_B: "",
          Columna_C: this.total_abonos_credito,
        },
        {
          Columna_A: "Abono a proveedores (-)",
          Columna_B: "",
          Columna_C: this.total_abonos_prov,
        },
        {
          Columna_A: "Total gastos (-)",
          Columna_B: "",
          Columna_C: this.total_gastos,
        },
        {
          Columna_A: "Total",
          Columna_B: "",
          Columna_C: this.total_metodos_movimientos,
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "GASTOS",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (-)",
          Columna_B: this.cuadre.gastos.efectivo,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (-)",
          Columna_B: this.cuadre.gastos.transferencia,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (-)",
          Columna_B: this.cuadre.gastos.datafono,
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Total venta de bonos regalos",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (+)",
          Columna_B: this.cuadre.bonos.efectivo,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (+)",
          Columna_B: this.cuadre.bonos.transferencia,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (+)",
          Columna_B: this.cuadre.bonos.datafono,
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Total anticipos",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (+)",
          Columna_B: this.cuadre.anticipos.efectivo,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (+)",
          Columna_B: this.cuadre.anticipos.transferencia,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (+)",
          Columna_B: this.cuadre.anticipos.datafono,
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Total abonos a prestamos Prof",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (+)",
          Columna_B: this.cuadre.abonos.prestamos.efectivo,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (+)",
          Columna_B: this.cuadre.abonos.prestamos.transferencia,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (+)",
          Columna_B: this.cuadre.abonos.prestamos.datafono,
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Total abonos a proveedores",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (+)",
          Columna_B: this.cuadre.abonos.proveedores.efectivo,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (+)",
          Columna_B: this.cuadre.abonos.proveedores.transferencia,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (+)",
          Columna_B: this.cuadre.abonos.proveedores.datafono,
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Abono a Facturas por Cobrar",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (+)",
          Columna_B: this.cuadre.abonos.facturas.efectivo,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (+)",
          Columna_B: this.cuadre.abonos.facturas.transferencia,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (+)",
          Columna_B: this.cuadre.abonos.facturas.datafono,
          Columna_C: "",
        },
        {
          Columna_A: "",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "SALDOS NETOS",
          Columna_B: "",
          Columna_C: "",
        },
        {
          Columna_A: "Efectivo (+)",
          Columna_B: this.caja_efectivo_movimientos,
          Columna_C: "",
        },
        {
          Columna_A: "Transferencia (+)",
          Columna_B: this.caja_transferencia_movimientos,
          Columna_C: "",
        },
        {
          Columna_A: "Datafono (+)",
          Columna_B: this.caja_datafono_movimientos,
          Columna_C: "",
        },
      ];
      let excel = XLSX.utils.json_to_sheet(data.concat(body));
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    moment(data, format) {
      return moment(data, format);
    },
    viewDetailDialog(type) {
      this.contentDialogDetail = null;
      let content = {
        title: "",
        headers: [],
        body: [],
        footer: null,
        paginate: null,
        loading: true,
        type,
      };
      switch (type) {
        case "gastos":
          content.loading = true;
          content.title = `Detalle Gastos: ${moment(this.date).format("ll")}`;

          content.paginate = {
            perPage: 25,
            page: 1,
            total: 0,
          };

          content.headers = [
            {
              text: "Gasto",
              sortable: false,
              value: "gasto",
              width: "100px",
            },
            {
              text: "Categoria",
              sortable: false,
              value: "categoria",
              width: "100px",
            },
            {
              text: "Profesional",
              sortable: false,
              value: "profesional",
              width: "100px",
            },
            {
              text: "Total",
              sortable: false,
              value: "currency_total",
              width: "100px",
            },
            {
              text: "Efectivo",
              sortable: false,
              value: "currency_efectivo",
              width: "100px",
            },
            {
              text: "Transferencia",
              sortable: false,
              value: "currency_transfer",
              width: "100px",
            },
            {
              text: "Datafono",
              sortable: false,
              value: "currency_datafono",
              width: "100px",
            },
          ];

          content.footer = {
            text: "Total",
            colspan: 3,
            totalizacion: ["total", "efectivo", "transfer", "datafono"],
          };

          break;

        case "bonos":
          content.loading = true;
          content.title = `Detalle Bono regalo: ${moment(this.date).format(
            "ll"
          )}`;

          content.paginate = {
            perPage: 25,
            page: 1,
            total: 0,
          };

          content.headers = [
            {
              text: "Codigo",
              sortable: false,
              value: "codigo",
              width: "100px",
            },
            {
              text: "Cliente",
              sortable: false,
              value: "cliente",
              width: "100px",
            },
            {
              text: "ID / CC",
              sortable: false,
              value: "cedula",
              width: "100px",
            },
            {
              text: "Valor",
              sortable: false,
              value: "currency_valor",
              width: "100px",
            },
            {
              text: "Efectivo",
              sortable: false,
              value: "currency_efectivo",
              width: "100px",
            },
            {
              text: "Transferencia",
              sortable: false,
              value: "currency_transfer",
              width: "100px",
            },
            {
              text: "Datafono",
              sortable: false,
              value: "currency_datafono",
              width: "100px",
            },
          ];

          content.footer = {
            text: "Total",
            colspan: 3,
            totalizacion: ["valor", "efectivo", "transfer", "datafono"],
          };
          break;

        case "anticipos":
          content.loading = true;
          content.title = `Detalle Anticipos: ${moment(this.date).format(
            "ll"
          )}`;

          content.paginate = {
            perPage: 25,
            page: 1,
            total: 0,
          };

          content.headers = [
            {
              text: "Cliente",
              sortable: false,
              value: "cliente",
              width: "100px",
            },
            {
              text: "ID / CC",
              sortable: false,
              value: "cedula",
              width: "100px",
            },
            {
              text: "Valor",
              sortable: false,
              value: "currency_valor",
              width: "100px",
            },
            {
              text: "Efectivo",
              sortable: false,
              value: "currency_efectivo",
              width: "100px",
            },
            {
              text: "Transferencia",
              sortable: false,
              value: "currency_transfer",
              width: "100px",
            },
            {
              text: "Datafono",
              sortable: false,
              value: "currency_datafono",
              width: "100px",
            },
          ];

          content.footer = {
            text: "Total",
            colspan: 2,
            totalizacion: ["valor", "efectivo", "transfer", "datafono"],
          };
          break;

        case "abonosP":
          content.loading = true;
          content.title = `Detalle Abonos a prestamos Prof: ${moment(this.date).format(
            "ll"
          )}`;

          content.paginate = {
            perPage: 25,
            page: 1,
            total: 0,
          };

          content.headers = [
            {
              text: "Profesional",
              sortable: false,
              value: "profesional",
              width: "130px",
            },
            {
              text: "ID / CC",
              sortable: false,
              value: "cedula",
              width: "100px",
            },
            {
              text: "Prestamo",
              sortable: false,
              value: "gasto",
              width: "110px",
            },
            {
              text: "Abono",
              sortable: false,
              value: "currency_valor",
              width: "100px",
            },
            {
              text: "Efectivo",
              sortable: false,
              value: "currency_efectivo",
              width: "100px",
            },
            {
              text: "Transferencia",
              sortable: false,
              value: "currency_transfer",
              width: "100px",
            },
            {
              text: "Datafono",
              sortable: false,
              value: "currency_datafono",
              width: "100px",
            },
          ];

          content.footer = {
            text: "Total",
            colspan: 3,
            totalizacion: ["valor", "efectivo", "transfer", "datafono"],
          };
          break;

        case "abonosPro":
          content.loading = true;
          content.title = `Detalle Abonos a Proveedores: ${moment(this.date).format(
            "ll"
          )}`;

          content.paginate = {
            perPage: 25,
            page: 1,
            total: 0,
          };

          content.headers = [
            {
              text: "Proveedor",
              sortable: false,
              value: "proveedor",
              width: "130px",
            },
            {
              text: "ID / CC",
              sortable: false,
              value: "cedula",
              width: "100px",
            },
            {
              text: "N° Orden",
              sortable: false,
              value: "orden",
              width: "110px",
            },
            {
              text: "N° abono",
              sortable: false,
              value: "abono",
              width: "110px",
            },
            {
              text: "Abono",
              sortable: false,
              value: "currency_valor",
              width: "100px",
            },
            {
              text: "Efectivo",
              sortable: false,
              value: "currency_efectivo",
              width: "100px",
            },
            {
              text: "Transferencia",
              sortable: false,
              value: "currency_transfer",
              width: "100px",
            },
            {
              text: "Datafono",
              sortable: false,
              value: "currency_datafono",
              width: "100px",
            },
          ];

          content.footer = {
            text: "Total",
            colspan: 4,
            totalizacion: ["valor", "efectivo", "transfer", "datafono"],
          };
          break;

        case "abonosFa":
          content.loading = true;
          content.title = `Detalle Abonos a Proveedores: ${moment(this.date).format(
            "ll"
          )}`;

          content.paginate = {
            perPage: 25,
            page: 1,
            total: 0,
          };

          content.headers = [
            {
              text: "Cliente",
              sortable: false,
              value: "cliente",
              width: "130px",
            },
            {
              text: "ID / CC",
              sortable: false,
              value: "cedula",
              width: "100px",
            },
            {
              text: "N° Factura",
              sortable: false,
              value: "factura",
              width: "110px",
            },
            {
              text: "N° abono",
              sortable: false,
              value: "abono",
              width: "110px",
            },
            {
              text: "Abono",
              sortable: false,
              value: "currency_valor",
              width: "100px",
            },
            {
              text: "Efectivo",
              sortable: false,
              value: "currency_efectivo",
              width: "100px",
            },
            {
              text: "Transferencia",
              sortable: false,
              value: "currency_transfer",
              width: "100px",
            },
            {
              text: "Datafono",
              sortable: false,
              value: "currency_datafono",
              width: "100px",
            },
          ];

          content.footer = {
            text: "Total",
            colspan: 4,
            totalizacion: ["valor", "efectivo", "transfer", "datafono"],
          };
          break;

        default:
          break;
      }
      this.contentDialogDetail = content;
      this.loadDetails(type);
      this.$refs.detalleDialog.dialogActivator();
    },
    loadDetails(type) {
      let body = null;
      switch (type) {
        case "gastos":
          body = {
            route: `/gastos?page=${this.contentDialogDetail.paginate.page}`,
            params: {
              date: {
                inicio: this.date,
                fin: this.date,
              },
              categoria: -2,
              perPage: this.contentDialogDetail.paginate.perPage,
            },
          };

          this.$store
            .dispatch("get_data", body)
            .then((response) => {
              if (response.data.success) {
                this.contentDialogDetail.paginate.total = response.data.total;
                this.contentDialogDetail.body = response.data.data.data.map(
                  (g) => {
                    const total = parseFloat(g.valor) + parseFloat(g.iva);
                    const efectivo = parseFloat(g.efectivo);
                    const transfer = parseFloat(g.transfer);
                    const datafono = parseFloat(g.datafono);
                    const currency_total =
                      this.$options.filters.currency(total);
                    const currency_efectivo =
                      this.$options.filters.currency(efectivo);
                    const currency_transfer =
                      this.$options.filters.currency(transfer);
                    const currency_datafono =
                      this.$options.filters.currency(datafono);
                    return {
                      gasto: g.gasto,
                      categoria: g.categoria,
                      profesional: g.profesional,
                      total,
                      efectivo,
                      transfer,
                      datafono,
                      currency_total,
                      currency_efectivo,
                      currency_transfer,
                      currency_datafono,
                    };
                  }
                );
              }
            })
            .finally(() => (this.contentDialogDetail.loading = false));
          break;

        case "bonos":
          body = {
            route: `/bonos?page=${this.contentDialogDetail.paginate.page}`,
            params: {
              date: {
                inicio: this.date,
                fin: this.date,
              },
              estado: 0,
              perPage: this.contentDialogDetail.paginate.perPage,
            },
          };

          this.$store
            .dispatch("get_data", body)
            .then((response) => {
              if (response.data.success) {
                this.contentDialogDetail.paginate.total =
                  response.data.data.total;
                this.contentDialogDetail.body = response.data.data.data.map(
                  (bono) => {
                    const valor = parseFloat(bono.valor);
                    const efectivo = parseFloat(bono.efectivo);
                    const transfer = parseFloat(bono.transfer);
                    const datafono = parseFloat(bono.datafono);
                    const currency_valor =
                      this.$options.filters.currency(valor);
                    const currency_efectivo =
                      this.$options.filters.currency(efectivo);
                    const currency_transfer =
                      this.$options.filters.currency(transfer);
                    const currency_datafono =
                      this.$options.filters.currency(datafono);

                    return {
                      codigo: bono.codigo,
                      cliente: bono.cliente,
                      cedula: bono.cc_cliente,
                      valor: bono.valor,
                      efectivo,
                      transfer,
                      datafono,
                      currency_valor,
                      currency_efectivo,
                      currency_transfer,
                      currency_datafono,
                    };
                  }
                );
              }
            })
            .finally(() => (this.contentDialogDetail.loading = false));
          break;
        case "anticipos":
          body = {
            route: `/anticipos?page=${this.contentDialogDetail.paginate.page}`,
            params: {
              date: {
                inicio: this.date,
                fin: this.date,
              },
              estado: 0,
              perPage: this.contentDialogDetail.paginate.perPage,
            },
          };

          this.$store
            .dispatch("get_data", body)
            .then((response) => {
              if (response.data.success) {
                this.contentDialogDetail.paginate.total =
                  response.data.data.total;
                this.contentDialogDetail.body = response.data.data.data.map(
                  (anticipo) => {
                    const valor = parseFloat(anticipo.valor);
                    const efectivo = parseFloat(anticipo.efectivo);
                    const transfer = parseFloat(anticipo.transfer);
                    const datafono = parseFloat(anticipo.datafono);
                    const currency_valor =
                      this.$options.filters.currency(valor);
                    const currency_efectivo =
                      this.$options.filters.currency(efectivo);
                    const currency_transfer =
                      this.$options.filters.currency(transfer);
                    const currency_datafono =
                      this.$options.filters.currency(datafono);

                    return {
                      cliente: anticipo.cliente,
                      cedula: anticipo.cc_cliente,
                      valor: anticipo.valor,
                      efectivo,
                      transfer,
                      datafono,
                      currency_valor,
                      currency_efectivo,
                      currency_transfer,
                      currency_datafono,
                    };
                  }
                );
              }
            })
            .finally(() => (this.contentDialogDetail.loading = false));
          break;
        case "abonosP":
          body = {
            route: `/prestamos_desc_detalle?page=${this.contentDialogDetail.paginate.page}`,
            params: {
              date: {
                inicio: this.date,
                fin: this.date,
              },
              estado: 1,
              perPage: this.contentDialogDetail.paginate.perPage,
            },
          };

          this.$store
            .dispatch("get_data", body)
            .then((response) => {
              if (response.data.success) {
                this.contentDialogDetail.paginate.total =
                  response.data.data.total;
                this.contentDialogDetail.body = response.data.data.data.map(
                  (abono) => {
                    const valor = parseFloat(abono.valor);
                    const efectivo = parseFloat(abono.efectivo);
                    const transfer = parseFloat(abono.transfer);
                    const datafono = parseFloat(abono.datafono);
                    const currency_valor =
                      this.$options.filters.currency(valor);
                    const currency_efectivo =
                      this.$options.filters.currency(efectivo);
                    const currency_transfer =
                      this.$options.filters.currency(transfer);
                    const currency_datafono =
                      this.$options.filters.currency(datafono);

                    return {
                      profesional: abono.profesional,
                      cedula: abono.cedula,
                      gasto: abono.gasto,
                      valor,
                      efectivo,
                      transfer,
                      datafono,
                      currency_valor,
                      currency_efectivo,
                      currency_transfer,
                      currency_datafono,
                    };
                  }
                );
              }
            })
            .finally(() => (this.contentDialogDetail.loading = false));
          break;
        case "abonosPro":
          body = {
            route: `/detalle_abonos_proveedores?page=${this.contentDialogDetail.paginate.page}`,
            params: {
              date: {
                inicio: this.date,
                fin: this.date,
              },
              estado: 1,
              perPage: this.contentDialogDetail.paginate.perPage,
            },
          };

          this.$store
            .dispatch("get_data", body)
            .then((response) => {
              if (response.data.success) {
                this.contentDialogDetail.paginate.total =
                  response.data.data.total;
                this.contentDialogDetail.body = response.data.data.data.map(
                  (abono) => {
                    const valor = parseFloat(abono.valor);
                    const efectivo = parseFloat(abono.efectivo);
                    const transfer = parseFloat(abono.transfer);
                    const datafono = parseFloat(abono.datafono);
                    const currency_valor =
                      this.$options.filters.currency(valor);
                    const currency_efectivo =
                      this.$options.filters.currency(efectivo);
                    const currency_transfer =
                      this.$options.filters.currency(transfer);
                    const currency_datafono =
                      this.$options.filters.currency(datafono);

                    return {
                      proveedor: abono.proveedor,
                      cedula: abono.nit,
                      orden: this.$options.filters.folio(parseFloat(abono.orden)),
                      abono: this.$options.filters.folio(parseFloat(abono.abono)),
                      valor,
                      efectivo,
                      transfer,
                      datafono,
                      currency_valor,
                      currency_efectivo,
                      currency_transfer,
                      currency_datafono,
                    };
                  }
                );
              }
            })
            .finally(() => (this.contentDialogDetail.loading = false));
          break;

        case "abonosFa":
          body = {
            route: `/detalle_abonos_factura?page=${this.contentDialogDetail.paginate.page}`,
            params: {
              date: {
                inicio: this.date,
                fin: this.date,
              },
              estado: 1,
              perPage: this.contentDialogDetail.paginate.perPage,
            },
          };

          this.$store
            .dispatch("get_data", body)
            .then((response) => {
              if (response.data.success) {
                this.contentDialogDetail.paginate.total =
                  response.data.data.total;
                this.contentDialogDetail.body = response.data.data.data.map(
                  (abono) => {
                    const valor = parseFloat(abono.valor);
                    const efectivo = parseFloat(abono.efectivo);
                    const transfer = parseFloat(abono.transfer);
                    const datafono = parseFloat(abono.datafono);
                    const currency_valor =
                      this.$options.filters.currency(valor);
                    const currency_efectivo =
                      this.$options.filters.currency(efectivo);
                    const currency_transfer =
                      this.$options.filters.currency(transfer);
                    const currency_datafono =
                      this.$options.filters.currency(datafono);

                    return {
                      cliente: abono.cliente,
                      cedula: abono.cedula,
                      factura: this.$options.filters.folio(parseFloat(abono.factura)),
                      abono: this.$options.filters.folio(parseFloat(abono.abono)),
                      valor,
                      efectivo,
                      transfer,
                      datafono,
                      currency_valor,
                      currency_efectivo,
                      currency_transfer,
                      currency_datafono,
                    };
                  }
                );
              }
            })
            .finally(() => (this.contentDialogDetail.loading = false));
          break;

        default:
          break;
      }
    },
    changePage(page) {
      if (this.contentDialogDetail) {
        this.contentDialogDetail.paginate.page = page;
        this.loadDetails(this.contentDialogDetail.type);
      }
    },
  },
  created() {
    this.loadCuadreCaja();
    this.loadProfesionales();
    this.loadCierresZ();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes", "recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    info_profesionales() {
      const cuadre = this.cuadre.pagos || [];
      // Lista de profesionales activos
      const profesionales = this.profesionales.map((p) => {
        return {
          id: p.id,
          profesional: p.nombre,
          ventas: 0,
          total: 0,
        };
      });
      // lista de pagos por profesionales activos
      const pagos = cuadre.filter((pago) => {
        return (
          pago.profesional_id != "undefined" &&
          pago.profesional != "sin registro"
        );
      });

      // Asignar pago a profesional correspondiente
      profesionales.forEach((p) => {
        pagos.forEach((pago) => {
          if (p.id == pago.profesional_id) {
            p.ventas = parseFloat(pago.ventas);
            p.total = parseFloat(pago.total);
          }
        });
      });

      // Lista de pago por profesionales desactivados o eliminados
      const profesional_delete = cuadre
        .filter((pago) => {
          return (
            pago.profesional_id == "undefined" &&
            pago.profesional != "sin registro"
          );
        })
        .map((pago) => {
          return {
            id: null,
            profesional: pago.profesional,
            ventas: parseFloat(pago.ventas),
            total: parseFloat(pago.total),
          };
        });
      // Lista profesionales sin registro de nombre
      const no_registro = cuadre.filter((pago) => {
        return (
          pago.profesional_id == "undefined" &&
          pago.profesional == "sin registro"
        );
      });
      // Profesionales sin registro agrupados
      profesional_delete.push({
        id: null,
        profesional: "Profesional eliminado",
        ventas: no_registro.reduce((index, item) => {
          return index + parseFloat(item.ventas);
        }, 0),
        total: no_registro.reduce((index, item) => {
          return index + parseFloat(item.total);
        }, 0),
      });
      return profesionales
        .sort(
          (a, b) =>
            a.profesional.toLowerCase() > b.profesional.toLowerCase() || -1
        )
        .concat(profesional_delete);
    },
    total_ventas_profesionales() {
      return this.info_profesionales.reduce((index, item) => {
        return index + item.ventas;
      }, 0);
    },
    total_profesionales() {
      return this.info_profesionales.reduce((index, item) => {
        return index + item.total;
      }, 0);
    },
    total_bonos() {
      return (
        this.cuadre.bonos.efectivo +
        this.cuadre.bonos.transferencia +
        this.cuadre.bonos.datafono
      );
    },
    total_anticipos() {
      return (
        this.cuadre.anticipos.efectivo +
        this.cuadre.anticipos.transferencia +
        this.cuadre.anticipos.datafono
      );
    },
    caja_efectivo() {
      return (
        this.cuadre.metodos.efectivo +
        this.cuadre.bonos.efectivo +
        this.cuadre.anticipos.efectivo
      );
    },
    caja_transferencia() {
      return (
        this.cuadre.metodos.transferencia +
        this.cuadre.bonos.transferencia +
        this.cuadre.anticipos.transferencia
      );
    },
    caja_datafono() {
      return (
        this.cuadre.metodos.datafono +
        this.cuadre.bonos.datafono +
        this.cuadre.anticipos.datafono
      );
    },
    caja_efectivo_movimientos() {
      return (
        this.caja_efectivo -
        this.cuadre.gastos.efectivo +
        this.cuadre.abonos.facturas.efectivo +
        this.cuadre.abonos.prestamos.efectivo -
        this.cuadre.abonos.proveedores.efectivo
      );
    },
    caja_transferencia_movimientos() {
      return (
        this.caja_transferencia -
        this.cuadre.gastos.transferencia +
        this.cuadre.abonos.facturas.transferencia +
        this.cuadre.abonos.prestamos.transferencia -
        this.cuadre.abonos.proveedores.transferencia
      );
    },
    caja_datafono_movimientos() {
      return (
        this.caja_datafono -
        this.cuadre.gastos.datafono +
        this.cuadre.abonos.facturas.datafono +
        this.cuadre.abonos.prestamos.datafono -
        this.cuadre.abonos.proveedores.datafono
      );
    },
    total_metodos() {
      return this.caja_efectivo + this.caja_transferencia + this.caja_datafono;
    },
    total_metodos_movimientos() {
      return (
        this.caja_efectivo_movimientos +
        this.caja_transferencia_movimientos +
        this.caja_datafono_movimientos
      );
    },
    total_gastos() {
      return (
        this.cuadre.gastos.efectivo +
        this.cuadre.gastos.transferencia +
        this.cuadre.gastos.datafono
      );
    },
    total_abonos_credito() {
      return (
        this.cuadre.abonos.facturas.efectivo +
        this.cuadre.abonos.facturas.transferencia +
        this.cuadre.abonos.facturas.datafono
      );
    },
    total_abonos_prof() {
      return (
        this.cuadre.abonos.prestamos.efectivo +
        this.cuadre.abonos.prestamos.transferencia +
        this.cuadre.abonos.prestamos.datafono
      );
    },
    total_abonos_prov() {
      return (
        this.cuadre.abonos.proveedores.efectivo +
        this.cuadre.abonos.proveedores.transferencia +
        this.cuadre.abonos.proveedores.datafono
      );
    },
    dia_cierre() {
      const cierre_dia = this.cierres.filter((c) => {
        return c.fecha == this.date;
      });
      return cierre_dia.length > 0 ? true : false;
    },
    cierres_lista() {
      let cierres = this.cierres.map((c) => {
        return Object.assign({ delete: false }, c);
      });
      if (cierres.length != 0) {
        cierres[0].delete = true;
      }
      return cierres;
    },
  },
};
</script>

<style scoped>
.active {
  background: rgba(0, 0, 0, 0.1) !important;
}
</style>
